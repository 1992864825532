const breakpoints = {
    values: {
        xxs: 0,
        xs: 375,
        sm: 600,
        md: 960,
        lg: 1100,
        xl: 1200,
    },
};

export default breakpoints;
