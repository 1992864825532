import { getUserFromAPI } from "../api/support";



const authTokenUtils = () => {
    const tokenOnLocal = window.localStorage.getItem("userLocalToken");

    if(tokenOnLocal){
        return getUserFromAPI(tokenOnLocal)
    }
}

export default authTokenUtils;