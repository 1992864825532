import {HelpCircle} from "react-feather";

const UseDashboardItems = () => {
    const items = [
        {
            title: "Support Center",
            hidden: false,
            pages: [
                {
                    href: "/",
                    icon: HelpCircle,
                    title: "Support Center",
                    hidden: false,
                },
            ],
        },
    ];

    const dashboardItems = items.filter((item) => !item.hidden);

    return {dashboardItems};
};

export default UseDashboardItems;
