import variants from "./variants";
import {customPurple} from "./variants";
import {purpleBorder1} from "./globalStyles";
import {cardBoxShadow, cardBoxShadowHover} from "./shadows";

const buttonBoxShadow = "-1px 4px 27px -8px rgba(0, 0, 0, 0.36)";
const buttonBoxShadowHover = "-2px 5px 30px -10px rgba(0, 0, 0, 0.36)";

const components = {
    MuiButtonBase: {
        defaultProps: {
            disableRipple: true,
        },
        styleOverrides: {
            contained: {
                borderRadius: "24px",
                fontFamily: "Bree Serif",
            },
        },
    },

    MuiButton: {
        styleOverrides: {
            contained: {
                borderRadius: "24px",
                boxShadow: buttonBoxShadow,
                transition: "all .3s ease-in-out",
                "&:hover": {
                    boxShadow: buttonBoxShadowHover,
                    transform: "translateY(-3px)",
                },
            },
            outlined: {
                borderRadius: "24px",
                transition: "all .3s ease-in-out",
                "&:hover": {
                    transform: "translateY(-3px)",
                },
            },
        },
        variants: [
            {
                props: {variant: "defy-btn-primary"},
                style: {
                    borderRadius: "24px",
                    color: customPurple["50"],
                    backgroundColor: customPurple["800"],
                    fontFamily: "Bree Serif",
                    fontSize: "1rem",
                    fontWeight: 400,
                    height: "3rem",
                    width: "12.3125rem",
                    textTransform: "capitalize",
                    boxShadow: buttonBoxShadow,
                    transition: "all 0.3s ease-in-out",
                    "&:hover": {
                        backgroundColor: customPurple["600"],
                        boxShadow: buttonBoxShadowHover,
                        transform: "translateY(-3px)",
                    },
                },
            },
            {
                props: {variant: "defy-btn-secondary"},
                style: {
                    borderRadius: "24px",
                    color: "#FEFEFE",
                    backgroundColor: variants[0].sidebar.background,
                    fontFamily: "Bree Serif",
                    fontSize: "1rem",
                    fontWeight: 400,
                    height: "3rem",
                    width: "12.3125rem",
                    textTransform: "capitalize",
                    transition: "all 0.3s ease-in-out",
                    "&:hover": {
                        backgroundColor: variants[0].sidebar.background,
                        color: "#FEFEFE",
                        boxShadow: buttonBoxShadowHover,
                        transform: "translateY(-3px)",
                    },
                },
            },
            {
                props: {variant: "defy-btn-view-all"},
                style: {
                    borderRadius: "24px",
                    color: customPurple["800"],
                    backgroundColor: customPurple["50"],
                    fontFamily: "Bree Serif",
                    fontSize: "1rem",
                    fontWeight: 400,
                    width: "12.3125rem",
                    height: "3rem",
                    boxShadow: buttonBoxShadow,
                    textTransform: "capitalize",
                    transition: "all 0.3s ease-in-out",
                    "&:hover": {
                        color: customPurple["800"],
                        backgroundColor: customPurple["50"],
                        boxShadow: buttonBoxShadowHover,
                        transform: "translateY(-3px)",
                    },
                },
            },
            {
                props: {variant: "defy-btn-get"},
                style: {
                    borderRadius: "24px",
                    color: "#080809",
                    backgroundColor: customPurple["50"],
                    fontFamily: "Bree Serif",
                    fontSize: "1rem",
                    fontWeight: 400,
                    height: "3rem",
                    width: "12.3125rem",
                    boxShadow: buttonBoxShadow,
                    textTransform: "capitalize",
                    transition: "all 0.3s ease-in-out",
                    "&:hover": {
                        color: "#080809",
                        backgroundColor: customPurple["50"],
                        boxShadow: buttonBoxShadowHover,
                        transform: "translateY(-3px)",
                    },
                },
            },
        ],
    },
    MuiLink: {
        defaultProps: {
            underline: "hover",
        },
    },
    MuiCard: {
        styleOverrides: {
            root: {
                borderRadius: "6px",
                // boxShadow: "-1px 4px 17px -8px rgba(0, 0, 0, 0.30)",
                boxShadow: "0px 0px 9px 0px rgb(158 ,158 ,158 , 0.30)",
                // border: purpleBorder1,
                border: 'none!important',
                transition: "all .3s ease-in-out",

                "&:hover": {
                    boxShadow: "-2px 5px 30px -10px rgba(0, 0, 0, 0.36)",
                },
            },
        },
    },
    MuiPickersDay: {
        styleOverrides: {
            day: {
                fontWeight: "300",
            },
        },
    },
    MuiPickersYear: {
        styleOverrides: {
            root: {
                height: "64px",
            },
        },
    },
    MuiPickersCalendar: {
        styleOverrides: {
            transitionContainer: {
                marginTop: "6px",
            },
        },
    },
    MuiPickersCalendarHeader: {
        styleOverrides: {
            iconButton: {
                backgroundColor: "transparent",
                "& > *": {
                    backgroundColor: "transparent",
                },
            },
            switchHeader: {
                marginTop: "2px",
                marginBottom: "4px",
            },
        },
    },
    MuiPickersClock: {
        styleOverrides: {
            container: {
                margin: `32px 0 4px`,
            },
        },
    },
    MuiPickersClockNumber: {
        styleOverrides: {
            clockNumber: {
                left: `calc(50% - 16px)`,
                width: "32px",
                height: "32px",
            },
        },
    },
    MuiPickerDTHeader: {
        styleOverrides: {
            dateHeader: {
                "& h4": {
                    fontSize: "2.125rem",
                    fontWeight: 400,
                },
            },
            timeHeader: {
                "& h3": {
                    fontSize: "3rem",
                    fontWeight: 400,
                },
            },
        },
    },
    MuiPickersTimePicker: {
        styleOverrides: {
            hourMinuteLabel: {
                "& h2": {
                    fontSize: "3.75rem",
                    fontWeight: 300,
                },
            },
        },
    },
    MuiPickersToolbar: {
        styleOverrides: {
            toolbar: {
                "& h4": {
                    fontSize: "2.125rem",
                    fontWeight: 400,
                },
            },
        },
    },
    MuiChip: {
        styleOverrides: {
            root: {
                borderRadius: "24px",
            },
        },
        variants: [
            {
                props: {variant: "defy-blog-category-chip"},
                style: {
                    padding: "5px",
                    height: 30,
                    lineHeight: "30px",
                    background: customPurple["50"],
                    color: customPurple["800"],
                    borderRadius: "13px",
                    border: `0.5px solid ${customPurple["800"]}`,
                    fontSize: "18px",
                    cursor: "pointer",
                    "&:hover": {
                        backgroundColor: "rgba(119, 0, 255, 0.06)",
                    },
                },
            },
            {
                props: {variant: "defy-blog-category-chip-active"},
                style: {
                    padding: "5px",
                    height: 30,
                    lineHeight: "30px",
                    background: customPurple["500"],
                    color: customPurple["900"],
                    borderRadius: "13px",
                    border: `0.5px solid ${customPurple["900"]}`,
                    fontSize: "18px",
                    cursor: "pointer",
                    "&:hover": {
                        backgroundColor: "rgba(119, 0, 255, 0.06)",
                    },
                },
            },
        ],
    },

    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                borderRadius: "13px",
                backgroundColor: customPurple[50],
                border: "none",
                boxShadow: cardBoxShadow,
                fontWeight: "bold",
                transition: "all .3s ease-in-out",

                "&:hover": {
                    boxShadow: cardBoxShadowHover,
                },

                // "& fieldset": {
                //   border: "none",
                //   borderRadius: "13px",
                // },

                // "&:hover fieldset": {
                //   border: "none",
                // },

                // "&.Mui-focused fieldset": {
                //   border: "none",
                //   borderRadius: "13px",
                // },

                // "& .MuiInputBase-input": {
                //   border: "none",
                //   borderRadius: "13px",
                // },

                // "& .Mui-focused .MuiInputBase-input": {
                //   border: "none",
                // },
            },
        },
    },

    MuiPagination: {
        styleOverrides: {
            outlined: {
                "&.MuiPaginationItem-root": {
                    color: variants[0].palette.grey08,
                },
            },
        },
    },
};

export default components;
