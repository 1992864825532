import async from "./utils/Async";


import Page404 from "./pages/Page404";
import AuthLayout from "./layouts/Auth";
// import Messages from "./pages/Messages";
import DashboardLayout from "./layouts/Dashboard";

import RedirectPage from "./pages/RedirectPage";

const Support = async( () => import("./pages"));

const Messages = async( () => import ("./pages/Messages" ) );
const Categories = async( () => import ("./pages/Categories/index" ) );



const routes = [
    {
        path: "/",
        element: <DashboardLayout/>,
        children: [
            {
                path: "",
                element: <Support/>,
            },
            {
                path: "messages/:id",
                element: <Messages />,
            },
        ],
    },
    {
        path: "/categories",
        element: <DashboardLayout/>,
        children: [
            {
                path: "all",
                element: <Categories/>,
            },
            {
                path: "create",
                element: <Support/>,
            },
            {
                path: "update/:id",
                element: <Messages />,
            },
        ],
    },
    {
        path: "//redirect-to",
        element: <AuthLayout/>,
        children: [
            {
                path: "/redirect-to",
                element: <RedirectPage/>,
            },
        ],
    },
    {
        path: "*",
        element: <AuthLayout/>,
        children: [
            {
                path: "*",
                element: <Page404/>,
            },
        ],
    },
];

export default routes;