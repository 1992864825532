import axios from "axios";
import {BASE_URL, token} from "../constants";

const supportTicketToken = localStorage.getItem('userLocalToken') ?? ""

const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${token}`
};

const params = {
    userType: "ADMIN",
    userId: 57
}

// export const getAgents = () => {
//     return axios.get(`${BASE_URL}/support-ticket/agents/all`);
// };

export const getAgents = (supportTicketToken) => {
    return axios.get(`${BASE_URL}/support-ticket/assignee/all?${supportTicketToken}`)
}


export const getTicketCounts = (data) => {
    return axios.get(`${BASE_URL}/support-ticket/ticket-counter?${data}`);
};

export const createTicket = (data, token,  config) => {
    return axios.post(`${BASE_URL}/support-ticket/create?${token}`, data);
};

export const updateTicket = (data, token) => {
    return axios.put(`${BASE_URL}/support-ticket/update?${token}`, data);
};

export const getAllTickets = (filters) => {
    console.log(filters)
    return axios.get(`${BASE_URL}/support-ticket/ticket/all?${filters}`);
};

// ====== Category APIs ========
export const getAllCategories = (supportTicketToken) => {
    return axios.get(`${BASE_URL}/support-ticket/category/get/all?${supportTicketToken}`);
}
export const createCategory = (data, supportTicketToken) => {
    return axios.post(`${BASE_URL}/support-ticket/category/create?${supportTicketToken}`, data);
};

export const getSingleCategory = (categoryId, supportTicketToken) => {
    return axios.get(`${BASE_URL}/support-ticket/category/get/${categoryId}?${supportTicketToken}`);
};

export const updateCategory = (data,supportTicketToken) => {
    // console.log(data)
    return axios.put(`${BASE_URL}/support-ticket/category/update?${supportTicketToken}`, data);
};

export const deleteCategory = (categoryId, supportTicketToken) => {
    return axios.delete(`${BASE_URL}/support-ticket/category/delete/${categoryId}?${supportTicketToken}`);
}
// ====== end of Category APIs ========

// ====== Message APIs =============
export const getAllMessages = (filters) => {
    return axios.get(`${BASE_URL}/support-ticket/message?${filters}`);
};
export const createMessage = (data ,token) => {
    return axios.post(`${BASE_URL}/support-ticket/message/create?${token}`, data);
};
export const uploadFile = (data ,token ) => {
    return axios.post(`${BASE_URL}/support-ticket/file/upload?${token}`, data);
};
// ===== end Of Message API ======





export const getUserFromAPI = (token) => {
    return axios.get(`${BASE_URL}/support-ticket/info/session/${token}`);
}
