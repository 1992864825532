import { customPurple } from "./variants";

const textFieldInputProps = {
  style: {
    fontSize: "1.125rem",
    lineHeight: "1rem",
    padding: "10.5px 15px",
  },
};

const imageDefaultStyle = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
};

const purpleBorder1 = `1px solid ${customPurple[100]}`;

const longerTextStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
}

export { textFieldInputProps, imageDefaultStyle, purpleBorder1, longerTextStyle };
