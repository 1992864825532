import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import "react-app-polyfill/stable";
import {BrowserRouter} from "react-router-dom";
import reportWebVitals from "./utils/reportWebVitals";
import {ThemeProvider} from "./contexts/ThemeContext";
import {ConfirmProvider} from "material-ui-confirm";

import "../src/styles/prwire.scss"

ReactDOM.render(
    <BrowserRouter>
        <ThemeProvider>
            <ConfirmProvider>
                <App/>
            </ConfirmProvider>
        </ThemeProvider>
    </BrowserRouter>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
