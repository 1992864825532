import React from "react";
import styled from "styled-components/macro";
import {CircularProgress} from "@mui/material";

import { customPurple } from "../../theme/variants";

const Root = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

function Loader() {
    return (
        <Root>
            <CircularProgress style={{ color: customPurple[800] }} />
        </Root>
    );
}

export default Loader;
