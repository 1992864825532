import React from "react";
import { create } from "jss";
import { Provider } from "react-redux";
import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components/macro";
import { HelmetProvider, Helmet } from "react-helmet-async";

import jssPreset from "@mui/styles/jssPreset";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import StylesProvider from "@mui/styles/StylesProvider";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";

import "./i18n";
import routes from "./routes";
import createTheme from "./theme";
import { store } from "./redux/store";
import useTheme from "./hooks/useTheme";
import { AuthProvider } from "./contexts/JWTContext";

import authTokenUtils from '../src/utils/AuthTokenUtils'
import { useEffect } from "react";
import { MAIN_URL } from "./constants";


const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});



function App() {
  const content = useRoutes(routes);
  const { theme } = useTheme();

  const navigate = useNavigate()


  useEffect( () => {
    console.log(MAIN_URL)
    authTokenUtils()
    .then( (res) => {
      if(!res.data.success){
        // changed...
        window.location.href = `${MAIN_URL}/signin`;
      }
    })
    .catch( err => {
      console.log(err.response)
      window.location.href = `${MAIN_URL}/signin`;
    })
    
  })


  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | MINAWILL"
        defaultTitle="Minawill Support Ticket"
      />

      <Provider store={store}>
        <StylesProvider jss={jss}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StyledEngineProvider injectFirst>
              <MuiThemeProvider theme={createTheme(theme)}>
                <ThemeProvider theme={createTheme(theme)}>
                  <AuthProvider>{content}</AuthProvider>
                  <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                </ThemeProvider>
              </MuiThemeProvider>
            </StyledEngineProvider>
          </LocalizationProvider>
        </StylesProvider>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
