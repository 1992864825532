import React, {useState} from "react";
import {Outlet} from "react-router-dom";
import styled from "styled-components/macro";

import {spacing} from "@mui/system";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Box, CssBaseline, Paper as MuiPaper} from "@mui/material";

import Footer from "../components/Footer";
import Navbar from "../components/navbar/Navbar";
import GlobalStyle from "../GlobalStyle";
import Sidebar from "../components/sidebar/Sidebar";
import useDashboardItems from "../components/sidebar/useDashboardItems";


import logo from '../../src/images/logo.png'

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({children}) => {
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const {dashboardItems} = useDashboardItems();
    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

    return (
        <Root className="container">
            <CssBaseline/>
            <GlobalStyle/>
            {/* <Drawer>
                <Box sx={{display: {xs: "block", lg: "none"}}}>
                    <Sidebar
                        open={mobileOpen}
                        showFooter={false}
                        variant="temporary"
                        items={dashboardItems}
                        onClose={handleDrawerToggle}
                        PaperProps={{style: {width: drawerWidth}}}
                    />
                </Box>
                <Box sx={{display: {xs: "none", md: "block"}}}>
                    <Sidebar
                        PaperProps={{style: {width: drawerWidth}}}
                        items={dashboardItems}
                        showFooter={false}
                    />
                </Box>
            </Drawer> */}
            <AppContent>
                <Box sx={{textAlign: 'center', padding: '30px 30px 0 30px'}}>
                    <img
                        alt="logo"
                        width={'220px'}
                        height={"100%"}
                        src={logo}
                    />
                </Box>
                {/* <Navbar onDrawerToggle={handleDrawerToggle}/> */}
                <MainContent p={isLgUp ? 12 : 5} className="main-app-root-container">
                    {children}
                    <Outlet/>
                </MainContent>
                <Footer/>
            </AppContent>
        </Root>
    );
};

export default Dashboard;
